@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@700&family=Poppins:wght@400;500;700&family=Roboto:ital@0;1&display=swap");

@font-face {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    src: url('./fonts/Font Awesome/FONT AWESOME 6 PRO-SOLID-900.OTF') format('opentype');
}

@font-face {
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    src: url('./fonts/Font Awesome/FONT AWESOME 6 PRO-REGULAR-400.OTF') format('opentype');
}

@font-face {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    src: url('./fonts/Font Awesome/FONT AWESOME 6 PRO-LIGHT-300.OTF') format('opentype');
}

:root {
    --is-mobile-bp: 1023;
    --logo-height: 4rem;
    --logo-max-height: 4rem;
    --header-height: calc(var(--logo-height) + var(--layout-padding-y));
    --footer-height: 3.0rem;
    --footer-height-mobile: 3.0rem;
    --footer-height-desktop: 2.5rem;
    --layout-padding-y: 1.3rem;
    --layout-padding-x: 1.3rem;
    --main-height: calc(100vh - var(--footer-height-desktop));
    --main-padding-top: calc(var(--header-height) + var(--layout-padding-y));
    --main-part-height: calc(var(--main-height) - var(--main-padding-top) - 0.5rem);
    --dark-grey: #333;
    --med-dark-grey-2: #504745;
    --med-dark-grey: #6a6a6a;
    --med-grey: #989898;
    --med-light-grey: #e7e7e7;
    --light-grey: #d6d6d6;
    --very-light-grey: #f8f9fa;
    --dark-yellow: #97750b;
    --med-dark-yellow-2: #c39b1d;
    --med-dark-yellow: #fabc05;
    --star-yellow: #e3bb40;
    --med-yellow: #f2c94c;
    --med-light-yellow: #f6db87;
    --light-yellow: #fcefca;
    --very-light-yellow: #fff9e7;
    --dark-blue: #035763;
    --med-blue: #137c8a;
    --light-blue: #95e0e5;
    --very-light-blue: #f2feff;
}

@media (max-width: 768px)  { 
    :root {
        --main-height: calc(100vh); 
    }
}

/* Colors */ 

.black {
    color: black !important;
}

.dark-grey {
    color: var(--dark-grey) !important;
}

.med-dark-grey {
    color: var(--med-dark-grey);
}

.med-grey {
    color: var(--med-grey) !important;
}

.med-grey-border {
    border: 0.5px solid var(--med-grey);
}

.light-grey {
    color: var(--light-grey) !important;
}

.dark-yellow {
    color: var(--dark-yellow);
}

.med-yellow {
    color: var(--med-yellow);
}

.star-yellow {
    color: var(--star-yellow) !important;
}

.light-yellow {
    color: var(--light-yellow);
}

.dark-blue {
    color: var(--dark-blue);
}

.med-blue {
    color: var(--med-blue);
}

.bg-very-light-yellow {
    background: var(--very-light-yellow);
}

.bg-med-light-grey {
    background: var(--med-light-grey);
}

/* Font family */

.font-assistant {
    font-family: "Assistant", sans-serif;
}

.font-roboto {
    font-family: "Roboto", sans-serif;
}

/* Font size */

.xxl-font {
    font-size: 1.375rem;
}

.xl-font {
    font-size: 1.375rem;
}

.lg-font {
    font-size: 1.25rem;
}

.md-font {
    font-size: 1rem;
}

.sm-font {
    font-size: 0.875rem;
}

.xs-font {
    font-size: 0.75rem;
}

.xxs-font {
    font-size: 0.625rem;
}

/* Font weight */

.w-700 {
    font-weight: 700;
}

/* Icons */

.fa-globe,
.fa-phone,
.fa-hospital {
    font-family: "Font Awesome 6 Pro";
}

/* .fa-hospital::before {
    content: "\f0f8";
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
} */

.fa-circle-location-arrow::before {
    content: "\f602";
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
}

.fa-location-dot::before {
    content: "\f3c5";
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
}

.fa-scalpel::before {
    content: "\f61d";
    font-family: "Font Awesome 6 Pro";
    color: var(--med-blue);
}

.fa-message-exclamation::before {
    content: "\f4a5";
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    color: var(--dark-grey);
}

.fa-circle-info::before {
    content: "\f05a";
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    color: var(--dark-grey);
}

.fa-filter::before {
    content: "\f0b0";
    font-family: "Font Awesome 6 Pro";
}

.fa-user-doctor::before {
    content: "\f0f0";
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
}

.fa-star,
.fa-star-half-alt {
    font-family: "Font Awesome 6 Pro";
}

.fa-regular .fa-star:not(.font-awesome-regular-ready .fa-star),
.fa-regular .fa-star-half-alt:not(.font-awesome-regular-ready .fa-star-half-alt),
.fa-solid .fa-star:not(.font-awesome-solid-ready .fa-star),
.fa-solid .fa-star-half-alt:not(.font-awesome-solid-ready .fa-star-half-alt) {
    display: none;
}

.huge-icon {
    font-size: 64px !important;
}

/* General */

* {
    padding: 0px;
    margin: 0px;
    color: var(--dark-grey);
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    box-sizing: border-box !important;
    scroll-margin-top: var(--header-height);
    scroll-behavior: auto !important;
    /* letter-spacing: normal; */
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.overflow-y-auto-hover *,
.overflow-y-auto-hover:hover {
    visibility: visible;
}

.white-space-pre-line {
    white-space: pre-line;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-fit-content,
.w-fit-content div {
    width: fit-content;
}

.w-min-content,
.w-min-content div {
    width: min-content;
}

.w-max-content,
.w-max-content div {
    width: max-content !important;
}

.w-min-100-max-content,
.w-min-100-max-content div {
    width: max-content;
    min-width: 100%;
}


.inherit-font-style {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
}

.bold {
    color: black;
    text-align: center;
    font-family: "Assistant", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
}

.strong {
    font-weight: bold;
}

.transparent,
.transparent .modal-content {
    background-color: transparent;
    border-color: transparent;
}

i {
    color: inherit;
    line-height: inherit !important;
    font-size: 1rem;
    font-style: normal;
    padding: 0rem 0.5rem;
}

a, a:not([href]):not([class]) {
    color: var(--med-blue);
    text-decoration: none;
    text-decoration-line: underline;
    cursor: pointer;
}

a.a-only-hover-decoration {
    color: inherit;
    font-size: inherit;
}

a.a-only-hover-decoration,
a.a-no-decoration-line {
    text-decoration-line: none;
}

a:hover::before, a:not([href]):not([class]):hover,
a.a-only-hover-decoration:hover {
    color: var(--med-blue) !important;
    text-decoration: underline;
    font-weight: 500;
}

a:hover i, a:not([href]):not([class]) i {
    text-decoration: underline var(--very-light-grey) !important;
}

a.a-no-decoration-line:hover {
    text-decoration-line: none;
}

.title {
    font-family: "Assistant", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
}

.tooltip {
    z-index: 99999 !important;
    background-color: var(--dark-blue);
    position: absolute;
}

[class*="tooltip"] {
    border-radius: 6px !important;
}

.pointer {
    cursor: pointer;
}

.cursor-inherit {
    cursor: inherit !important;
}

.select-min-height .react-select__control {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    min-height: 0 !important;
}

.select-no-border .react-select__control {
    padding-left: 0 !important;
    border-width: 0 !important;
}

.select-no-border.react-select--is-disabled .react-select__control {
    background-color: inherit;
}

.w-max-content {
    width: max-content;
}

.h-max-content {
    height: max-content;
}

.mh-25vh {
    min-height: 25vh;
}

.h-2 {
    height: 2rem;
}

.h-3 {
    height: 3rem;
}

.h-main {
    height: var(--main-part-height);
    max-height: var(--main-part-height);
    box-sizing: border-box !important; 
}

.lh-normal {
    line-height: normal;
}

.padding-header {
    padding-top: var(--main-padding-top) !important;
    box-sizing: content-box !important; 
}

.d-none {
    display: none;
}

.one-line-text {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.one-line-text:hover {
    overflow: visible;
    display: inline-block;
}

.column-gap-10p {
    column-gap: 10%;
}

.gray-circle {
    border-radius: 100px;
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: white;
}

::-webkit-scrollbar-thumb {
    background: var(--light-grey);
    border-radius: 50px;
    height: 50%;
}

.overflow-y-auto-hover {
    overflow-y: auto;
    visibility: hidden;
}

/* Header & footer */

header {
    position: fixed;
    /* width: 100%; */
    height: var(--header-height);
    min-height: var(--header-height);
    z-index: 999;
    filter: none;
}

main {
    position: relative;
    width: 100%;
    padding-top: var(--main-padding-top);
    padding-bottom: 0;
    min-height: var(--main-height);
}

.footer {
    align-items: center;
    background-color: var(--med-yellow);
    height: var(--footer-height-desktop);
    min-height: var(--footer-height-desktop);
}

.footer-content,
.footer-content a,
.footer-content-smaller,
.footer-content-smaller a {
    color: var(--dark-blue) !important;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 1.25rem;
}

.footer-content-smaller,
.footer-content-smaller a {
    font-size: 0.7rem;
}

.footer i {
    color: inherit;
    font-size: inherit;
    padding: 8px;
    padding-left: 0px;
}

footer {
    position: sticky;
    bottom: 0;
    width: 100%;
}

.logo {
    padding: 0rem 0rem;
    height: var(--logo-height);
}

.navbar {
    background-color: white;
    padding-left: var(--layout-padding-x);
    padding-right: var(--layout-padding-x);
    padding-top: var(--layout-padding-y);
    padding-bottom: var(--layout-padding-y);
    width: 100vw;
    text-transform: none;
    box-sizing: border-box !important;
}

.navbar-nav {
    column-gap: 3.625rem;
    margin-right: 3.625rem;
}

.navbar-collapse {
    justify-content: flex-end;
}

.nav-item {
    margin-right: 0rem;
}

.nav-link {
    color: var(--dark-blue) !important;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.dropdown-menu {
    padding: 0px;
    border-color: white;
    border-radius: 0.25rem;
    box-shadow: 4px 8px 12px 6px rgba(120, 120, 120, 0.15);
}

.dropdown-title,
.dropdown-item {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 6px 12px;
    align-items: center;
}


.dropdown-item {
    color: var(--dark-grey) !important;
}

.dropdown-item:hover {
    background-color: var(--very-light-grey);
}

.nav-link.active {
    font-weight: 700;
    text-decoration: underline;
}

.dropdown-item.active,
.dropdown-item:focus {
    color: white !important;
    background-color: var(--dark-blue) !important;
}

.dropdown-toggle::after {
    border: none !important;
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f107" !important;
    vertical-align: 0;
}

.navbar-brand {
    margin: 0px;
}

.navbar-icon-border {
    color: inherit;
    display: flex;
    width: 1.0rem;
    height: 1.5rem;
    padding: 0.125rem 0.5rem;
    padding-left: 0;
    margin-right: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.625rem;
    border-radius: 3.125rem;
}

.navbar-icon-border:has(.fa-user) {
    background: var(--light-grey);
}

.navbar i {
    font-family: "Font Awesome 6 Pro";
    font-size: 0.625rem;
}

/* Tables */

th {
    font-size: inherit;
    text-transform: capitalize;
}

th,
td {
    padding: 0.5rem !important;
}

.formTable {    
    border-width: 0;
    border-color: white;
    border-collapse: separate;
}

.formTable td,
.formTable input {    
    font-size: 0.9rem !important;
}

/* modal */

.modal {
    z-index: 20;
}

.modal-dialog {
    max-width: 90%;
}

.modal-backdrop {
    z-index: 10;
    --bs-backdrop-bg: none;
}

*:has(.modal.show) main {
    filter: blur(2px);
}

.col-sm-9 {
    display: flex;
    vertical-align: middle;
}

.user-modal .modal-content {
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-radius: 0.625rem;
    border: 1px solid var(--dark-grey);
    background: var(--very-light-yellow);
    box-shadow: 1px 4px 20px 5px rgba(0, 0, 0, 0.10);
}

.user-modal.modal-white .modal-content {
    background: white;
}

.modal-content-h-25vh-plus-300 .modal-content {
    min-height: calc(25vh + 300px) !important;
}

/* pagination */

.paginationButton,
.paginationText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6875rem;
}

.paginationButton {
    width: 1rem;
    height: 1rem;
    border: 0px;
    border-radius: 0.125rem;
    background: transparent;
}

.paginationButton:hover {
    border: 1px solid var(--med-grey);
}

.paginationButton:disabled {
    border: 0px;
}

.paginationButton.selected {
    border: 0.75px solid var(--med-grey);
    background: var(--med-light-yellow);
}

/* alert */

.alert {
    width: fit-content;
    border-radius: 0.625rem;
    border: 2px solid;
    padding: 0.375rem 1rem;
    margin: 0;
}

.alert-danger {
    border-color: #CF4E31;
    background-color: #FDE6E1;
}

.alert-padding {
    padding: 6rem;
}

.alert-margin {
    margin: 6rem;
}

/* btn */

.btn {
    display: inline-flex;
    padding: 0.375rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: var(--dark-grey);
    border: 1.25px solid;
    border-radius: 0.25rem;
    text-transform: none;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    height: fit-content;
}

.btn:active {
    background-color: inherit !important;
}

.btn-primary {
    background: var(--med-yellow);
    border-color: var(--med-yellow);
}

.btn-secondary {
    background: white;
    border-color: var(--med-dark-yellow-2);
}

.btn-primary:hover,
.btn-secondary:hover {
    color: var(--dark-grey);
    background: var(--light-yellow);
    border-color: var(--dark-yellow);
}

.btn-primary:disabled {
    color: var(--med-grey);
    background: var(--light-grey);
    border-color: var(--light-grey);
}

.btn-secondary:disabled {
    color: var(--med-grey);
    background: white;
    border-color: var(--med-grey);
}

.btn-no-colors,
.btn-no-colors:hover,
.btn-no-colors:active,
.btn-no-colors:disabled {
    padding: 0 !important;
    background: white;
    border-color: white !important;
}

.btn-map,
.btn-map:hover,
.btn-map:active,
.btn-map:disabled {
    background: var(--light-yellow);
    border-color: var(--dark-yellow);
    border-radius: 4px 4px 0px 0px !important;
    border: 1px 1px 0px 1px !important; 
    border-bottom: 0 !important;
}

/* Forms */

select,
input,
.form-control,
.form-select,
.react-select__control,
.multiSelectContainer input {
    padding: 0.5rem 0.75rem !important;
    background-color: white;
    border-radius: 0.25rem !important;
    border: 1px solid var(--med-dark-grey) !important;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    width: 100%;
}

select,
input,
.form-select,
.multiSelectContainer input 
.react-datetime-picker__wrapper,
.form-control {
    margin: 0.3rem 0;
    padding: 0.3rem 0;
}

.dummy-input,
.dummy-input .form-control:focus {
    width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    border-color: white !important;
    background-color: white !important;
    box-shadow: none !important;
}

.form-label {
    margin: 0.3rem;
    padding: 0.3rem;
}

.col-sm-3:has(>.multiSelectContainer),
div:has(>.multiSelectContainer) {
    padding: 0.3rem;
    width: 100% !important;
    padding-right: 0 !important
}

div:has(>.select) {
    padding: 0.3rem;
}

.multiSelectContainer .searchWrapper {
    padding: 0;
    margin: 0;
    border-color: white;
}

.multiSelectContainer .searchWrapper .searchBox {
    padding: 0;
    margin: 0;
}

::placeholder {
    color: inherit;
}

.form-control:focus {
    background-color: white;
}

.pac-target-input {
    margin: 0 !important;
}

.pac-item:hover {
    background-color: var(--light-yellow);
    cursor: pointer;
}

input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    margin-inline-end: 0.3rem;
}

.switch-on input[type="checkbox"],
.switch-off input[type="checkbox"] {
    display: none;
}

.switch-on input[type="checkbox"] + label:before,
.switch-off input[type="checkbox"] + label:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    cursor: pointer;
}

.switch-on input[type="checkbox"]:disabled + label:before,
.switch-off input[type="checkbox"]:disabled + label:before {
    cursor: inherit;
}

.switch-on input[type="checkbox"] + label:before{
    color: var(--med-blue);
    content: "\e5b1 \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
}

.switch-off input[type="checkbox"] + label:before{
    color: var(--med-grey);
    content: "\e5b0 \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
}

.textarea {
    height: 7rem;
}

.textarea,
.blue-border {
    border: 1px solid var(--med-blue) !important;
}

.grey-border {
    border: 1px solid var(--med-dark-grey) !important;
}

.round-border {
    border-radius: 0.625rem !important;
}

.circle {
    border-radius: 100px;;
}

/* react-datetime */

.react-datetime-picker,
.react-datetime-picker__wrapper {
    border: 0px !important;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.react-datetime-picker__inputGroup__input {
    border: 0px !important;
    padding: 0 !important;
    margin: 0 !important;
}

.react-datetime-picker__inputGroup__amPm {
    width: auto !important;
}

.react-datetime-picker__button {
    display: none;
}

/* react-select */

.single-select,
.multi-select {
    width: 100%;
}

.react-select__indicator-separator {
    display: none;
}

.react-select__control * {
    border: 0px white !important;
    padding: 0 !important;
    margin: 0 !important;
    line-height: inherit !important;
    width: 100% !important;
    cursor: pointer;
}

.react-select__value-container {
    white-space: nowrap;
}

.react-select__menu {
    margin-top: 0 !important;
    width: max-content !important;
    min-width: 100% !important;
}

.react-select__menu-list {
    display: flex;
    padding: 0.5rem 0.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    border-radius: 0.125rem;
    background: white;
    box-shadow: 5px 5px 12px 2px rgba(120, 120, 120, 0.25), 0px 4px 20px 3px rgba(65, 65, 65, 0.25);
}

.react-select__option {
    padding: 0.25rem 0.5rem !important;
}

.react-select__option--is-focused {
    background-color: var(--light-yellow) !important;
    cursor: pointer !important;
}

.react-select__option--is-selected {
    background-color: inherit !important;
    color: inherit !important;
}

.single-select .react-select__option--is-selected {
    background-color: var(--med-yellow) !important;
}

.react-select__indicators {
    width: auto !important;
}

.react-select__control--is-focused {
    box-shadow: none !important;
}

.multi-select .react-select__control {
    display: flex;
    justify-content: flex-end;
}

.multi-select-counter {
    flex-grow: 0;
    padding: 0rem 0.25rem !important;
    margin: 0rem 0.25rem !important;
    border-radius: 0.875rem;
    background: var(--med-light-yellow);
}

.multi-select .react-select__option {
    display: list-item !important;
    list-style-position: none;
}

.multi-select  .react-select__option::before {
    content: "\f0c8";
    vertical-align: top;
    color: var(--dark-grey);
    font-family: "Font Awesome 6 Pro";
    font-size: 0.625rem;
    padding-right: 0.625rem;
}

.multi-select  .react-select__option--is-selected::before {
    content: "\f0c8";
    color: var(--med-blue);
    font-weight: 900;
}

.icon-select .react-select__menu {
    top: -2rem;
    right: -2rem;
}

.icon-select .single-select,
.icon-select .react-select__control,
.icon-select .react-select__control * {
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    border: 0 !important;
}

/* Address field */

.small-address-filter,
div:has(.small-address-filter) {
    position: relative !important;
    margin: 0;
    padding: 0;
}

.small-address-filter input {
    height: 5rem;
    border: 1px solid var(--med-dark-grey) !important;
    border-radius: 0.625rem !important;
    padding-bottom: 2rem !important;
}

.small-address-filter .use-my-location {
    height: 5rem;
    position: absolute;
    right: 0;
    bottom: -1rem;
    align-items: center;
    width: fit-content;
    justify-content: flex-end;
    white-space: nowrap !important;
    z-index: 100;
}

@media (min-width: 769px) {
    .small-address-filter .use-my-location {
        height: 3rem;
        right: 0;
        bottom: 0;
    }

    .small-address-filter input {
        height: 3rem;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.big-address-filter input {
    height: 7rem;
    border: 4px solid !important;
    border-color: var(--dark-blue) !important;
    border-radius: 3.125rem !important;
    padding: 1rem;
    padding-bottom: 2rem !important;
    width: 75vw; 
}

.big-address-filter .use-my-location {
    height: 7rem;
    position: absolute;
    right: 1rem;
    bottom: -2rem;
    align-items: center;
    width: fit-content;
    justify-content: flex-end;
    white-space: nowrap !important;
}

@media (min-width: 576px) {
    .big-address-filter .use-my-location {
        height: 5rem;
        right: 0;
        bottom: 0;
    }

    .big-address-filter input {
        height: 5rem;
        padding-bottom: 0 !important;
    }
}

/* Google map */ 

.doctors-map-below-results {
    position: fixed;
    width: 100%;
    bottom: var(--footer-height);
    right: 0;
}

.doctors-map-below-results .map {
    background-color: var(--very-light-grey);
    padding: 0;
    position: relative;
    min-height: 50vh;
    height: 50vh;
    z-index: 1;
}

.doctors-map-next-to-results {
    position: static;
}

.doctors-map-next-to-results .map {
    padding: 0;
    position: relative;
    min-height: var(--main-part-height);
    height: var(--main-part-height);
    z-index: 1;
}

.doctors-map-below-results:has(.gm-style) .map-is-loading,
.doctors-map-below-results:has(.gm-style) .map-is-loading *,
.doctors-map-next-to-results .map-is-loading,
.doctors-map-next-to-results .map-is-loading *,
.map:not(:has(.gm-style)) .btn {
    display: none;
}

.above-map {
    position: absolute;
    z-index: 2;
    text-align: end;
}

.above-map.add-doctor {
    bottom: 20px;
    right: 70px;
}

.above-map.legend {
    top: 0;
    right: 70px;
}

.above-map.legend .gm-control-active-copy {
    background: none rgb(255, 255, 255); 
    border: 0px;
    margin: 10px; 
    padding: 0px; 
    cursor: pointer;
    border-radius: 2px; 
    min-height: 40px; 
    min-width: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; 
}

.gm-style-mtc-bbw {
    display: none;
}

.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-tc {
    top: -25px;
}

.pac-container {
    position: fixed;
    z-index: 30;
}

.gm-style-iw,
.gm-style-iw-c,
.gm-style-iw-d {
    padding: 0 !important;
}

.gm-style-iw-d > div > div {
    height: 3.9rem !important;
    overflow: hidden;
}

/* Doctor's cards */

.doctorSmallCard,
.doctor-big-card {
    border-radius: 0.25rem;
    gap: 1rem;
    min-height: 8.875rem;
}

.doctor-small-card {
    width: 22rem;
}

.doctor-big-card {
    width: 100% !important;
    padding: 1.8rem 1.5rem !important;
}

.doctor-big-card .btn {
    line-height: 2rem;
    padding: 0 1rem;
}

.doctor-category {
    padding: 2px 8px 2px 8px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    background: var(--light-yellow);
    font-size: 0.75rem;
    height: 22px;
    color: var(--med-dark-grey-2) !important;
}

.doctor-speciality {
    padding: 4px 12px 4px 12px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    border: 1px var(--light-blue) solid !important;
    color: var(--med-blue);
    background: var(--very-light-blue);
    font-size: 0.75rem;
    height: 22px;
}

.verification img {
    height: 22px;
    filter: grayscale(100%);
}

.verification img:hover {
    filter: grayscale(0%);
}


.doctor-big-card .doctor-location-contact {
    display: inline-block;
    min-width: 260px;
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

.doctor-big-card .doctor-address,
.doctor-big-card .doctor-address *,
.doctor-big-card .doctor-location .btn {
    font-size: 0.875rem;
}

.doctor-big-card .doctor-address,
.doctor-big-card .doctor-address *,
.doctor-big-card .doctor-address i,
.doctor-location-btn-selected,
.doctor-big-card .doctor-location .btn {
    color: var(--med-dark-grey);
}

.doctor-small-card .doctor-address,
.doctor-small-card .doctor-address *,
.doctor-small-card .star-rating * {
    font-size: 0.75rem;
    max-width: 10rem;
}

.doctor-small-card .doctor-address,
.doctor-small-card .doctor-address * {
    overflow: hidden;
    text-overflow: ellipsis;
}

.doctor-small-card .doctor-address,
.doctor-small-card .doctor-address * {
    color: var(--med-dark-grey-2) !important;
}

/* Doctor's location */

.doctor-location-btn,
.doctor-location-btn-selected,
.doctor-location-btn:active {
    display: flex;
    flex-wrap: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--light-grey);
    gap: 0.75rem;
    padding: 0.25rem 0.75rem 0.2rem 0.75rem !important;
    width: fit-content;
}

.doctor-location-btn-selected {
    border-bottom-color: var(--med-grey);
}

.doctor-location-btn:hover,
.doctor-location-btn-selected:hover,
.doctor-location-btn:active,
.doctor-location-btn-selected:active  {
    border-bottom-color: var(--med-grey);
    background-color: transparent !important;
}

.doctor-location-private-label {
    color: #AB3319;
    font-weight: 700;
    text-transform: uppercase;
}

.doctor-image {
    border-radius: 0.25rem;
    object-fit: cover;
    padding: 0;
}

@media (hover: none) {
    a:hover::before, 
    a:not([href]):not([class]):hover,
    a.a-only-hover-decoration:hover, 
    a:hover i, 
    a.a-no-decoration-line:hover {
        color: inherit !important;
        text-decoration: inherit !important;
        /* font-family: inherit !important; */
        /* font-size: inherit !important; */
        /* font-weight: inherit !important; */
    }
}

/* Desktop */

@media (min-width: 1024px)  { 
    .only-mobile-and-tablets,
    .only-mobile-and-tablets *,
    .only-mobile,
    .only-mobile * {
        display: none !important;
    }

    .ps-2-desktop {
        padding-left: 8px !important;
    }

    .w-70-desktop {
        width: 70% !important;
    }

    .user-modal .modal-dialog {
        max-width: 50%;
    }

    .doctor-small-card {
        border: 1.5px solid transparent;
    }

    .doctor-small-card:hover {
        background-color: #F8F8F8 !important;
    }

    .doctor-small-card .h-doctor-image,
    .doctor-small-card .doctor-image {
        height: 125px;
        width: 100px;
    }

    .doctor-tiny-card .doctor-image {
        height: 67px;
        width: 54px;
    }

    .doctor-big-card .doctor-image {
        height: 178px;
        width: 142px;
    }
}

/* Tablets */

@media (min-width: 577px) and (max-width: 1023px)  { 
    .only-desktop,
    .only-desktop *,
    .only-mobile,
    .only-mobile * {
        display: none !important;
    }
}

/* Mobile & tablets */

@media (max-width: 1023px)  { 
    :root {
        --logo-height: 2rem;
        --logo-max-height: 2rem;
    }

    .w-mobile-100 {
        width: 100% !important;
    }

    .footer {
        height: var(--footer-height-mobile);
        min-height: var(--footer-height-mobile);
    }

    .footer-content,
    .footer-content a {
        font-size: 0.7rem;
        line-height: 0.7rem;
    }

    .footer-content-smaller,
    .footer-content-smaller a {
        font-size: 0.6rem;
        line-height: 1rem;
    }

    /* .gap-3 {
        gap: 0.6rem !important
    }

    .py-1, .pt-1 {
        padding-top: 0.2rem !important;
    }

    .py-1, .pb-1 {
        padding-bottom: 0.2rem !important;
    }

    .py-2, .pt-2 {
        padding-top: 0.35rem !important;
    }

    .py-2, .pb-2 {
        padding-bottom: 0.35rem !important;
    }

    .py-3, .pt-3 {
        padding-top: 0.5rem !important;
    }

    .py-3, .pb-3 {
        padding-bottom: 0.5rem !important;
    } */

    .navbar-collapse,
    .navbar-nav {
        width: 100%;
    }

    .dropdown-menu {
        padding-bottom: 8px;
        box-shadow: none;
        width: 100%;
    }

    .user-modal .modal-dialog {
        max-width: 100%;
    }

    .doctor-big-card {
        padding: 0rem !important;
    }

    .doctor-big-card .doctor-image,
    .doctor-small-card .h-doctor-image,
    .doctor-small-card .doctor-image {
        height: 125px;
        width: 100px;
    }

    .doctor-tiny-card .doctor-image {
        height: 67px;
        width: 54px;
    }

    .alert {
        width: auto;
        padding: 0.375rem 1rem;
        margin: 0;
    }

    .w-100vw-mobile {
        width: 100vw;
    }
}

/* Mobile */

@media (max-width: 576px)  { 
    .only-desktop,
    .only-desktop *,
    .only-tablets-and-desktop,
    .only-tablets-and-desktop * {
        display: none !important;
    }
    
    .doctor-big-card .doctor-location-contact {
        min-width: 90%;
        max-width: 90%;
    }

    .text-left-mobile {
        text-align: left !important;
    }
}